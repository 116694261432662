import { render, staticRenderFns } from "./FooterComponentEdunetma.vue?vue&type=template&id=8a78c622&scoped=true"
import script from "./FooterComponentEdunetma.vue?vue&type=script&lang=ts"
export * from "./FooterComponentEdunetma.vue?vue&type=script&lang=ts"
import style0 from "./FooterComponentEdunetma.vue?vue&type=style&index=0&id=8a78c622&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a78c622",
  null
  
)

export default component.exports